.user-details {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-left: 1.2em;
  top: -1.5em;
}

.user-header {
  display: flex;
  
  
  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.8));
}

.user-details-top {
  display: flex;
  flex-direction: column;

}

.user-image-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
 
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.8);

  width: 5em;
  height: 5em;
  /* -webkit-filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.8)); */
  -webkit-appearance: none;
}

.avatar-container {
  margin-top: -20px;
  width: 90px;
  height: 90px;
  border-radius: 50%;
  border: 5px solid white;
  background-color: white;
  overflow: hidden;
  /* -webkit-box-shadow: 10px 10px 41px -8px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 41px -8px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 10px 41px -8px rgba(0, 0, 0, 0.75); */
  transition: transform .2s;
}

.avatar-container:hover {
  transform: scale(1.05);
}

.avatar {
  border-radius: 50%;
  width: 105%;
  height: 105%;
  object-fit: cover;
  cursor: pointer;
  z-index: 1;
}

.verified-lottie {
  z-index: 2;
  background-color: transparent;
  position: absolute;
  left: 60px;
  top: -20px;
  width: 2em;
  height: 2em;
  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.7));
}

.user-header-name {
  display: flex;
  flex-direction: column;
  margin-left: 1em;
}

.user-header-name h1 {
  margin-top: 1.3em;
  margin-bottom: 0em;
}

.user-header-name h2 {
  font-family: SFProRegular;
  font-size: 18px;
  color: #A7A7A7;
  margin-top: 0em;
}

.address-button-container {
  display: flex;
  z-index: 2;
  max-width: 10em;
  margin-top: 1em;
}

.address-container {
  display: flex;
  background-color: #d9d9d922;
  padding: 0.5em 1em 0.5em 1em;
  border-radius: 20px;
  width: fit-content;
  cursor: pointer;
}

.address-button-container:hover {
  filter: brightness(70%);
  -webkit-filter: brightness(70%);
  transition: all .1s ease;
  -webkit-transition: all .1s ease;
  -moz-transition: all .1s ease;
  -o-transition: all .1s ease;
  -ms-transition: all .1s ease;
  cursor: pointer;
}

.address-label {
  font-family: SFProRegular;
  font-weight: 600;
  font-size: 14px;
  color: #A7A7A7;
  padding-right: 0.5em;
}

.address {
  font-family: SFProRegular;
  font-weight: 500;
  font-size: 14px;
  color: #A654F4;
  padding-right: 0.5em;
}

.address-clipboard {
  color: #A7A7A7;
  font-size: 14px;
  align-self: center;
}

.user-bio {
  color: #E1E8FD;
  width: 90%;
  font-family: SFProRegular;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  margin-top: 0.8em;
  margin-left: 0.4em;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.user-bio a {
  color: #d1bbf9;
}

.user-social-icons {

  margin-top: 0.7em;
  font-size: 24px;
  display: flex;
  justify-content: flex-start;
  width: 60%;
  height: 1.8em;
}

.user-social-icons a {


  display: flex;
  color: white;
  margin-right: .15em;
  padding: .4em;
  border-radius: 50%;
}

.social-icon:hover {
  background-color: #2C2C2C;
}

.user-total-score-container {
  font-family: SFProRegular;
  font-weight: 800;
  font-size: 18px;
  color: #A7A7A7;
  display: flex;
  justify-content: space-between;
  margin-top: 1em;
  margin-bottom: 2.5em;
}

.select-project {
  display: flex;
  flex-direction: column;
}

.select-project-title {
  font-size: 14px;
  margin-left: 0.4em;
  margin-bottom: 0.4em;
}

.select-project select {
  background: #363636;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.2);
  border: none;
  border-right: 12px solid transparent;
  border-radius: 16px;
  font-size: 16px;
  padding: 12px;
  color: white;
}

.user-total-score {
  display: flex;
  flex-direction: row-reverse;
  margin-right: 1em;
}

.user-total-score-number {
  color: white;
  margin-right: 0.35em;
}

.user-scoretype-tabs-container {
  display: flex;
  width: 92%;
  justify-content: flex-start;
  margin-left: 0.6em;
  margin-right: 1em;
  margin-bottom: 0.5em;
}

.user-scoretype-tab {
  display: flex;
  flex-direction: column;
  font-family: SFProRegular;
  font-weight: 900;
  color: #7A7A7A;
  align-items: center;
  cursor: pointer;
  margin-right: 25px;
}

.user-scoretype-tab:hover {
  color: #A654F4;
}

.user-scoretype-tab.selected {
  font-size: 17px;
  color: #A654F4;
}

.score-update-container {
  display: flex;
  flex-direction: column;
  width: 90%;
  font-family: SFProRegular;
  font-weight: 600;
  margin-top: 0.9em;
  margin-left: 0.6em;
}

.score-update {
  display: flex;
  width: 100%;
  justify-content: space-between;
  color: white;
  border-bottom: 1px solid rgba(122, 122, 122, 0.2);
  margin-bottom: 1em;
  padding-bottom: 0.5em;
}

.score-update-left {
  display: flex;
  width: 35%;
}

.score-update-date {
  display: none;
  margin-right: 4em;
}

.score-update-points {
  color: #A654F4;
}

.user-details-container {
  width: 100%;


  max-width: 1280px;
}

@media screen and (min-width: 600px) {
  .user-details-container {
    width: 90%;
    max-width: 600px;
  }

  .score-update-container {
    width: 100%;
  }

  .user-bio {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    font-smooth: always;
  }

  .user-total-score-container {
    justify-content: space-between;
  }

  .user-total-score {
    flex-direction: column;
    align-items: flex-end;
    margin-right: 0;
  }

  .user-total-score-number {
    color: white;
    font-size: 36px;
    font-weight: 900;
    margin-right: 0;
  }

  .score-update {
    padding-top: 0.6em;
    padding-bottom: 1.2em;
  }

  .score-update-date {
    display: block;
    width: 30%;
  }

  .score-update-action {
    width: 70%;
  }
}

@media screen and (min-width: 900px) {
  .user-details-container {
    width: 90%;
   
    max-width: 1280px;
  }

  .user-details-top {
    flex-direction: row;
  }

  .user-social-icons {
    margin-top: 1.2em;
    justify-content: flex-end;
  }
}