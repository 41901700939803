.closed {
  display: none;
}


.info-first-slot {

  display: flex;
  font-family: SFProRegular;
  font-weight: 600;
  cursor: pointer;
  text-align: start;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;


}
.info-second-slot {

  display: flex;
  font-family: SFProRegular;
  font-weight: 600;
  cursor: pointer;
  text-align: start;
  color: #7A7A7A;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;


}


.img-container {
  display: flex;
  position: relative;
  background-color: white;
  filter:drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.8));

}
.img-style{
  width:100%;
  height:100%;
}
.img {
  display: flex;
  border-radius: 50%;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.verified-container {
  filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.8));
  
  display: flex;
  z-index: 2;
  position: absolute;
  top: -4%;
  right: -14%;
  justify-content: center;
  align-items: center;
  width: calc(100%/2.3);
  height:calc(100%/2.3);

}