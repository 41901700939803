#side-bar {
  -webkit-box-shadow: 10px 0px 67px -5px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 0px 67px -5px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 0px 67px -5px rgba(0, 0, 0, 0.75);
}

.side-bar-title-explore-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding-left: 27.5px;
  margin-bottom: 15px;
}

.side-bar-title-explore {
  font-family: 'SFProHeavy';
  font-weight: 800;
  font-size: 18px;
  color: white;
  padding-left: 16.5px;
}

.social-img {
  image-rendering: optimizeQuality;
  object-fit: cover;
  width:94%;
  transition: all .2s ease-in-out;
  -webkit-box-shadow: 10px 10px 34px -19px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 34px -19px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 10px 34px -19px rgba(0, 0, 0, 0.75);
}

.glass-panel{

  display: flex;
  position: absolute;
  justify-content:center;
  flex-direction: column;
  align-items: center;
  height:100%;
  transition: all .2s ease-in-out;


}
.glass-panel:hover {
  transform: scale(1.05);
  cursor: pointer;

}
.discord-logo {
  image-rendering: optimizeQuality;

 
  width:32px;
}
.side-bar-title {
  font-family: 'SFProHeavy';
  font-weight: 800;
  font-size: 16px;
  color: #B8BED3;
}
.social-text{
  margin-left: 10px;
  font-family: 'SFProHeavy';
  font-weight: 800;
  font-size: 18px;
  color: white;
  text-shadow: rgba(0, 0, 0,.2) 1px 0 10px;
}

.side-bar-menu-bubble {
  -webkit-box-shadow: 10px 10px 34px -19px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 34px -19px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 10px 34px -19px rgba(0, 0, 0, 0.75);
}

.menu-item {
  display: flex;
  flex-direction: row;
  padding-left: 20px;
  align-items: center;
  transition: all .2s ease-in-out;
}

.menu-item:hover {
  transform: scale(1.05);
}