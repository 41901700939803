.card-container {
  filter: none;
  background-color: #242424;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
  padding: 20px 30px;
  align-items: center;
  -webkit-box-shadow: 10px 10px 41px -8px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 41px -8px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 10px 41px -8px rgba(0, 0, 0, 0.75);
  position: relative;
  border-radius: 0px 0px 15px 15px;
  /* padding-bottom: 20px; */
}

@media screen and (min-width: 510px) {
  .card-container {
    filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.5));
    border-radius: 25px;
    min-height: 420px;
    width: 500px;
  }
}