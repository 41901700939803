.drop-down-container {
  min-width: 150px;
  height: 40px;
  display: flex;
  align-items: center;
  background: #212121;
  border: 2px solid #2A2A2A;
  box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  position: relative;
  cursor: pointer;
  align-self: flex-start;
}

.drop-down-title-container {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
}

.drop-down-title {
  /* font-family: 'SFProHeavy'; */
  /* font-weight: 800; */
  font-size: 18px;
  color: white;
}

.drop-down-list {
  min-width: 150px;
  background: #212121;
  border: 2px solid #2A2A2A;
  box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  position: absolute;
  z-index: 1000;
  top: 40px;
  left: 0
}

.drop-down-item {
  width: '100%';
  height: 30px;
  display: flex;
  padding-left: 20px;
  padding-right: 20px;
  align-items: center;
  font-family: 'SFProBold';
  font-weight: 600;
  font-size: 14px;
  color: #A7A7A7;
  list-style-type: none
}

.drop-down-item>img {
  margin-right: 10px;
}

.drop-down-item:hover {
  background: #181818;
  color: white;
}

@keyframes rotateDown {
  0% {
    transform: rotate(-180deg)
  }

  100% {
    transform: rotate(0deg)
  }
}

@keyframes rotateUp {
  0% {
    transform: rotate(0deg)
  }

  100% {
    transform: rotate(-180deg)
  }
}

.toggleOut {
  animation: rotateDown 500ms;
  transform: rotate(0deg)
}

.toggleIn {
  animation: rotateUp 500ms;
  transform: rotate(-180deg)
}