.pagination-container {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.pagination-container>h2 {
  font-size: 16px;
  font-weight: 600;
  margin: 0 1px;
  color: #bbb;
  cursor: pointer;
}

.pagination-txt {
  font-size: 14px;
  font-weight: 600;
  padding: 2px 6px;
  /* background-color: red; */
  margin: 0 1px;
  color: #bbb;
  cursor: pointer;
}

.pagination-txt:hover {
  background-color: rgba(50, 50, 50, .5);
}