.shimmer {
  background-color: #2a2a2a;
  display: inline-block;
  -webkit-mask: linear-gradient(-60deg, #000 30%, #0005, #000 70%) right/300% 100%;
  background-repeat: no-repeat;
  animation: shimmer .5s infinite;
}

@keyframes shimmer {
  100% {
    -webkit-mask-position: left
  }
}