.redemption-card-outer {
  transition: all .75s ease-in-out;
}

.redemption-card {
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;
  background-color: #222222;
  border: 2px solid #303030;
  transition: all .1s ease-in-out;
}

.redemption-card:hover {
  transform: scale(1.05);
  -webkit-box-shadow: 0px 10px 21px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 10px 21px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 10px 21px 0px rgba(0, 0, 0, 0.75);
}

.redemption-card-img-contain {
  background-color: #101010;
}

/* .redemption-card-description {
  padding: 10px;
} */

.redemption-card-description>h1 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 18px;
  line-height: 22px;
  margin: 0;
}

.redemption-card-description>h2 {
  font-size: 16px;
  line-height: 22px;
}

.redemption-card-description>h3 {
  color: #A7A7A7;
  font-weight: 700;
  font-size: 12px;
  line-height: 22px;
}

.redemption-card-amount {
  margin-top: 10px;
  font-size: 12px;
  line-height: 22px;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}