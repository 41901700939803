body.active-modal {
  overflow-y: hidden;
}
.modal-qr {
  width: 100vw;
  height: 110vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  z-index: 1000;
  background: rgba(9, 9, 9, 0.8);
}



.modal-content-qr {
 display: flex;
 /* position: relative; */
 flex: 1;
 width: 100%;
 height: 100%;
 justify-content: center;
 align-items: center;
}