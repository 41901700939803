.tab-container {
  display: flex;
  flex-direction: row;
  position: relative;
  height: 44px;
  margin-bottom: 28px;
}

.tab {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 140px;
  height: '100%';
  color: #787878;
  transition: all .2 ease-in-out;
}

.tab:hover {
  color: white;
}

.tab.active {
  color: white;
}


.tab:hover {
  cursor: pointer;
}