.score-row-clip {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
  cursor: pointer;
}

.score-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 10px;
  width: 100%;
  cursor: pointer;
  transition: all .25s ease-in-out;
  border-radius: 10px;
}

.score-row:hover {
  transform: scale(1.01);
  -webkit-box-shadow: 0px 10px 38px -9px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 10px 38px -9px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 10px 38px -9px rgba(0, 0, 0, 0.75);
}

.score-row-left {
  width: fit-content;
  overflow-x: visible;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
}

.score-row-verified-lottie {
  background-color: transparent;
  position: relative;
  left: -15px;
  top: -30px;
  filter: drop-shadow(0px 0px 3px rgba(0, 0, 0, 0.8));
  z-index: 99;
}

.score-row-verified-lottie img {
  width: 20px;
  height: 20px;
}

.score-row-avatar {
  width: 3.4em;
  height: 3.4em;
  margin-left: 0.2em;
  margin-bottom: 0.2em;
  display: flex;
  justify-content: center;
  align-items: center;
}

.score-row-avatar-fix {
  z-index: 2;
  position: absolute;
  top: 0%;
  left: 0%;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 90%;
  height: 90%;
  border: 4px solid white;
}

.score-row-avatar img {
  border-radius: 50%;
  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.8));
  width: 3.4em;
  height: 3.4em;
  object-fit: cover;
  overflow: hidden;
  overflow-clip-margin: 0px;
  margin-left: 0.2em;
}

.score-row:hover img {
  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.8)) brightness(70%);
  transition: all .2s ease;
}

.score-row-display-name {
  display: flex;
  align-items: center;
  color: white;
  font-family: SFProRegular;
  font-weight: 600;
  margin-left: 1.5em;
  /* width: 40%; */
}

.score-row-address {
  align-items: center;
  color: #7A7A7A;
  font-size: 14px;
  font-family: SFProRegular;
  font-weight: 600;
  margin-left: 2em;
  overflow-x: visible;
}

.score-row-rank {
  font-family: SFProRegular;
  font-weight: 600;
  min-width: 27px;
}

.score-row-xp {
  display: block;
  text-align: end;
  font-family: SFProRegular;
  font-weight: 600;
  justify-content: flex-end;
  width: 25%;
}

@media screen and (min-width: 600px) {
  .score-row-avatar {
    margin-left: 1.5em;
  }
}

@media screen and (max-width: 900px) {
  .score-row-address {
    display: none;
  }
}